<!--  -->
<template>
  <div class="msg-list-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#0094EE',
        title: '消息',
        txtColor: '#fff',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="msg-header">
      <div class="title-box">
        <p class="title">收到的消息</p>
        <p class="rect"></p>
      </div>
      <p class="more-box" @click="handleAllRead">全部已读</p>
    </div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      class="list-box"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="list.length > 0"
      >
        <van-cell
          v-for="(item, i) in list"
          :key="i"
          class="list-item"
          @click="handleItem(item)"
        >
          <div class="left-img-box">
            <p
              v-if="!item.avaterUrl || item.avaterUrl == ''"
              style="
                width: 0.9rem;
                height: 0.9rem;
                border-radius: 50%;
                color: #969799;
                font-size: 14px;
                background-color: #f7f8fa;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <van-icon size="0.5rem" name="envelop-o" />
            </p>
            <van-image
              width="0.9rem"
              height="0.9rem"
              round
              v-else
              fit="cover"
              :src="item.avaterUrl"
            >
              <template v-slot:error
                ><van-icon size="0.5rem" name="envelop-o"
              /></template>
            </van-image>

            <!-- 1:未读0：已读 -->
            <p class="point" v-if="item.readType == 1"></p>
          </div>
          <div class="right-msg-box">
            <div class="msg-title-box">
              <p class="msg-title">{{ item.typeName }}</p>
              <p class="msg-time">{{ item.createTime | filterTime }}</p>
            </div>
            <span class="msg-content">
              <!-- <div v-html="item.msgContent"></div> -->{{ item.msgTitle }}
            </span>
          </div>
        </van-cell>
      </van-list>
      <van-empty v-else description="未获取到消息" />
    </van-pull-refresh>
    <van-popup
      v-model="showMsg"
      :style="{ 'min-height': '30%', 'max-height': '50%' }"
      round
      position="bottom"
      v-if="curItem"
    >
      <div class="header-box">
        <van-image
          width="0.9rem"
          height="0.9rem"
          fit="cover"
          class="img-header-msg"
          :src="curItem.avaterUrl ? curItem.avaterUrl : ''"
        />
        <div class="right-user-info">
          <p class="user-name">{{ curItem.staffName }}</p>
          <p class="depart-name">{{ curItem.departmentName }}</p>
        </div>
      </div>
      <div class="content-box">
        <div class="content">
          {{ curItem.msgContent }}
        </div>

        <p class="msg-time">{{ curItem.createTime }}</p>
      </div>
    </van-popup>
    <payrollPass
      :dialogPayroll.sync="dialogPayroll"
      :salaryMonth="salaryMonth"
      v-if="dialogPayroll"
      @updatePayroll="updatePayroll"
    />
    <TabBar
      :active.sync="activeName"
      @updatePayroll="updatePayroll"
      :updateData="updateData"
    />
  </div>
</template>

<script>
import TopWrapper from "@/components/topWrapper/index.vue";
import TabBar from "@/components/tabBar/index.vue";
import payrollPass from "@/components/payrollPass/index.vue";
import { msgGetList, readMessage, readAll } from "@/api/message/index.js";
import { Toast } from "vant";
import { getUrlParams } from "@/utils/index";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        type: 0, //消息类型：0-我的接收1-我的发布
        msgType: null, //	1-系统消息 2-个人消息
        homePageType: null, //是否展示在首页待办：0-否 1-是
      },
      listTotal: 0,
      showMsg: false,
      curItem: null,
      activeName: "msgList",
      timer: null,
      count: 2,
      isAll: false,
      dialogPayroll: false,
      salaryMonth: "",
      updateData: false, //更新已读数字
      refresh: null,
    };
  },
  filters: {
    filterTime(val) {
      if (!val) return "--:--";
      let nowDate = new Date();
      let nowYear = nowDate.getFullYear();
      let nowMonth = nowDate.getMonth() + 1;
      let nowDay = nowDate.getDate();

      let valArr = val.split(" ");
      let date = valArr[0].split("-");
      let year = date[0];
      let month = date[1];
      let day = date[2];
      if (nowYear == year && nowMonth == month && nowDay == day) {
        let time = valArr[1];
        let timeArr = time.split(":");
        let hour = timeArr[0],
          min = timeArr[1];
        return `${hour}:${min}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    },
  },
  components: { TopWrapper, TabBar, payrollPass },

  computed: {},

  mounted() {
    this.onLoad();
    // this.refresh = setTimeout(() => {
    //   this.$router.go(0);
    // }, 30000);
  },
  beforeDestroy() {
    clearTimeout(this.refresh);
  },
  methods: {
    updatePayroll(res) {
      this.dialogPayroll = res;
    },
    updateActive(name) {
      this.activeName = name;
    },
    handleAllRead() {
      readAll()
        .then((res) => {
          if (res) {
            let { code, msg } = res;
            if (code == 200) {
              this.updateData = true;
              this.params = {
                pageNum: 1,
                pageSize: 10,
                type: 0, //消息类型：0-我的接收1-我的发布
                msgType: null, //	1-系统消息 2-个人消息
                homePageType: null, //是否展示在首页待办：0-否 1-是
              };
              this.list = [];
              this.finished = false; //清空类表数据
              this.loading = true; //加载状态
              this.onLoad();
            } else {
              this.finished = true; //清空类表数据
              this.loading = false; //加载状态
            }
          }
        })
        .catch((e) => {
          this.finished = false; //清空类表数据
          this.loading = false; //加载状态
        });
    },
    readMsg(code, item) {
      readMessage(code).then((res) => {
        if (res) {
          let { msg, code } = res;
          if (code == 200) {
            this.updateData = true;
            this.jumpPage(item);
          }
        }
      });
    },
    handleItem(item) {
      //  跳转类型：1-审核 2-工资单 3-会议室 4-知识库 5-个人消息6-审批消息7-通知公告8-反馈消息9用车审批10邮件
      if (item.readType == 1) {
        this.readMsg(item.msgCode, item);
      } else {
        this.jumpPage(item);
      }
    },
    jumpPage(item) {
      const params = getUrlParams(item.targetUrlH5);
      if (item.targetType == 6) {
        let path = "/approvalDetail";
        if (item.tagKey === "PURCHASE_APPROVE") {
          path = "/approvalProcureDetail";
        } else if (item.tagKey === "CAR_APPLY_APPROVE") {
          path = "/approvalCarDetail";
        }
        this.$router.push({
          path: path,
          query: params,
        });
      } else if (item.targetType == 7 || item.targetType == 8) {
        this.$router.push({
          path: item.targetUrlH5,
          query: params,
        });
      } else {
        this.$router.push(item.targetUrlH5);
      }
    },

    onRefresh() {
      this.params.pageNum = 1;
      this.list = [];
      this.finished = false; //清空类表数据
      this.loading = false; //加载状态
      this.onLoad();
    },

    onLoad() {
      msgGetList(this.params)
        .then((res) => {
          this.loading = false;
          this.isAll = false;
          this.refreshing = false;
          if (res) {
            let { data, code } = res;
            if (code == 200) {
              this.list = this.list.concat(data.records);
              this.listTotal = data.total;

              if (this.list.length >= this.listTotal) {
                //如果没有数据了，把finished设置为true，之后就不会触发加载更多
                this.finished = true;
              } else {
                // this.params.pageNum++;
                if (this.params.pageNum >= data.pages) {
                  //如果没有数据了，把finished设置为true，之后就不会触发加载更多
                  this.finished = true;
                } else {
                  this.params.pageNum++;
                }
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.msg-list-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  .msg-header {
    width: 100%;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title-box {
      display: flex;
      flex-direction: column;
      height: 1rem;

      .title {
        padding-top: 0.2rem;
        font-size: 0.34rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
      }
      .rect {
        width: 1.36rem;
        height: 0.06rem;
        background: linear-gradient(
          135deg,
          #0094ee 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 0.04rem;
      }
    }
    .more-box {
      line-height: 1rem;
      font-weight: 500;
      font-size: 0.3rem;
      font-weight: 400;
      color: #0094ee;
    }
  }
  .list-box {
    background: #fff;
    min-height: 100%;
    .list-item {
      padding: 0.3rem;
      /deep/ .van-cell__value {
        display: flex;
        flex-direction: row;
      }
      .left-img-box {
        width: 0.9rem;
        height: 0.9rem;
        position: relative;
        flex: 0 0 0.9rem;
        .point {
          position: absolute;
          right: -0.02rem;
          top: 0rem;
          width: 0.28rem;
          height: 0.28rem;
          background: #f65f38;
          border-radius: 50%;
        }
      }
      .right-msg-box {
        margin-left: 0.2rem;
        flex: 1;
        overflow: hidden;
        .msg-title-box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .msg-title {
            font-size: 0.3rem;
            font-weight: 400;
            color: #333333;
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .msg-time {
            font-size: 0.22rem;
            color: #999999;
          }
        }
        .msg-content {
          margin-top: 0.1rem;
          flex: 1;
          font-size: 0.26rem;
          font-weight: 400;
          color: #999999;
          line-height: 0.37rem;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .header-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0.3rem;
    border-bottom: 0.01rem solid #e5e5e5;

    .img-header-msg {
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 0.08rem;
    }
    .right-user-info {
      margin-left: 0.2rem;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .user-name {
        font-size: 0.3rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.42rem;
        margin-bottom: 0.09rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .depart-name {
        font-size: 0.26rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.37rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .content-box {
    padding: 0.3rem;
    width: 100%;
    position: relative;
    min-height: 4rem;
    .content {
      width: 100%;
      margin-bottom: 0.3rem;
    }
    .msg-time {
      width: 100%;
      text-align: right;
      font-size: 0.26rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.37rem;
      position: absolute;
      right: 0.3rem;
      bottom: 0.3rem;
    }
  }
}
</style>
